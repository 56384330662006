exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-certificate-js": () => import("./../../../src/pages/certificate.js" /* webpackChunkName: "component---src-pages-certificate-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-massage-js": () => import("./../../../src/pages/massage.js" /* webpackChunkName: "component---src-pages-massage-js" */),
  "component---src-pages-policy-js": () => import("./../../../src/pages/policy.js" /* webpackChunkName: "component---src-pages-policy-js" */),
  "component---src-pages-relaxspa-index-js": () => import("./../../../src/pages/relaxspa/index.js" /* webpackChunkName: "component---src-pages-relaxspa-index-js" */),
  "component---src-pages-relaxspa-man-js": () => import("./../../../src/pages/relaxspa/man.js" /* webpackChunkName: "component---src-pages-relaxspa-man-js" */),
  "component---src-pages-relaxspa-woman-js": () => import("./../../../src/pages/relaxspa/woman.js" /* webpackChunkName: "component---src-pages-relaxspa-woman-js" */)
}

